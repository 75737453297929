import React from "react";
import "../custom.scss"
import background from "../images/angryimg.png"
import { Carousel, Card } from "react-bootstrap"

import MusikImg from "../images/musikladan_Caru.jpeg"



function CarouselPage() {
    return (
    <div>
        

        
    </div>
    );
}

export default CarouselPage;