import React from "react";
import "../custom.scss"


function Schedule() {
    return(
        <div>

        </div>
    );
}

export default Schedule;